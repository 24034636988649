/*
 * @Author       : ZM
 * @Date         : 2021-12-22 10:03:11
 * @LastEditors  : ZM
 * @LastEditTime : 2022-01-24 09:05:23
 * @Description  : is me
 */
import router from './router'
router.beforeEach((to, from, next) => {
    //to是路由切换的下一步去哪，from是路由切换来自哪里，next是同意下一步操作，类似于放行路由的切换
    console.log(to, from);
    //在嵌套一个if，当访问子路由时，才启动核查放行功能
    if (to.path !== '/login' && !sessionStorage.getItem('token')) {
        //跳非登录界面时，未登录状态跳到登录页
        next(`/login`);
        // return '/login';
        // next()
    } else {
        next()
    }

})