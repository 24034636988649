/*
 * @Author       : ZM
 * @Date         : 2021-11-01 08:34:55
 * @LastEditors  : ZM
 * @LastEditTime : 2022-02-15 09:37:49
 * @Description  : is me
 */
import http from "./public";
// const baseUrl = 'http://192.168.1.138:8080'; 
const baseUrl = '/api-management';   //


//获取图形验证码
export const getCodeImg = () => {
    return http.fetchGet(`${baseUrl}/captchaImage`);
}

// 登陆
export const login = (data: any) => {
    return http.fetchPost(`${baseUrl}/userInfo/login`, data);
};


export const getManageUserInfoList = (data: any) => {
    return http.fetchPost(`${baseUrl}/userInfo/list`, data);
};


export const updateManageUserInfo = (data: any) => {
    return http.fetchPost(`${baseUrl}/userInfo/update`, data);
};

export const updateManageUserAuth = (data: any) => {
    return http.fetchPost(`${baseUrl}/userInfo/editAuthSave`, data);
};

export const addManageUserInfo = (data: any) => {
    return http.fetchPost(`${baseUrl}/userInfo/add`, data);
};

export const changePassword = (data: any) => {
    return http.fetchPost(`${baseUrl}/userInfo/changePassword`, data);
};



export const deleteManageUserInfo = (data: any) => {
    return http.fetchPost(`${baseUrl}/userInfo/delete`, data);
};


//服务器列表 shareIpAddressController  
export const getIpAddress = (data: any) => {
    return http.fetchGet(`${baseUrl}/system/ip/list`, data);
};

// system/tbuser/addUser  //添加用户
export const addUser = (data: any) => {
    return http.fetchPost(`${baseUrl}/tbUser/addUser`, data);
};

//system/tbuser/countUserAndDeviceByIP  //根据IP统计用户和设备数量
export const countUserAndDeviceByIP = (data: any) => {
    return http.fetchGet(`${baseUrl}/system/tbuser/countUserAndDeviceByIP`, data);
};

//IMEI号管理
export const getImeiList = (data: any) => {
    return http.fetchPost(`${baseUrl}/device/getImeiList`, data);
};
// /system/tbdevice/deleteImei 根据imei号删除
export const deleteImei = (data: any) => {
    return http.fetchGet(`${baseUrl}/system/tbdevice/deleteImei`, data);
};

//system/tbdevice/addImei 根据imei号添加imei信息
export const addImei = (data: any) => {
    return http.fetchPost(`${baseUrl}/device/addDevImeis`, data);
};

//修改
export const editImei = (data: any) => {
    return http.fetchPost(`${baseUrl}/device/editImei`, data);
};

export const createDevImeis = (data: any) => {
    return http.fetchPost(`${baseUrl}/device/createDevImeis`, data);
};

///system/tbdevice/clearRegist    //清除注册
export const clearRegist = (data: any) => {
    return http.fetchPost(`${baseUrl}/device/clearRegist`, data);
};

//用户管理
//system/tbuser/deleteUser  //根据用户id删除
export const deleteUser = (data: any) => {
    return http.fetchGet(`${baseUrl}/system/tbuser/deleteUser`, data);
};

///system/tbuser/exportUserInfo  //导出用户
export const exportUserInfo = (data: any) => {
    return http.fetchGet(`${baseUrl}/system/tbuser/exportUserInfo`, data);
};

///system/tbuser/getAllUserList  //获取用户列表
export const getAllUserList = (data: any) => {
    return http.fetchPost(`${baseUrl}/tbUser/getAllUserList`, data);
};

//system/tbuser/getUserById   //根据用户ID查找用户
export const getUserById = (data: any) => {
    return http.fetchGet(`${baseUrl}/system/tbuser/getUserById`, data);
};

//system/tbuser/getUserDeviceById  //根据用户ID查询用户设备信息
export const getUserDeviceById = (data: any) => {
    return http.fetchGet(`${baseUrl}/system/tbuser/getUserDeviceById`, data);
};

//system/tbuser/initPsw  //更新用户初始化密码
// export const initPsw = (data: any) => {
//     return http.fetchGet(`${baseUrl}/system/tbuser/initPsw`, data);
// };

//system/tbuser/selectUserByUser  //根据用户名模糊查找父用户名
export const selectUserByUser = (data: any) => {
    return http.fetchGet(`${baseUrl}/system/tbuser/selectUserByUser`, data);
};

//system/tbuser/updateUserInfo //更新用户基本信息
export const updateUserInfo = (data: any) => {
    return http.fetchPost(`${baseUrl}/tbUser/editUser`, data);
};

//system/tbuser/updateUserStatus  //更新用户状态
// export const updateUserStatus = (data: any) => {
//     return http.fetchGet(`${baseUrl}/tbUser/updateUserStatus`, data);
// };

/** ------------------------------------------ */

export const getProductSpecificationsList = (data: any) => {
    return http.fetchPost(`${baseUrl}/productSpecifications/getList`, data);
};

export const addProductSpecifications = (data: any) => {
    return http.fetchPost(`${baseUrl}/productSpecifications/add`, data);
};

export const editProductSpecifications = (data: any) => {
    return http.fetchPost(`${baseUrl}/productSpecifications/edit`, data);
};

export const getProductEliminateDevShowReportList = () => {
    return http.fetchPost(`${baseUrl}/productSpecifications/getProductEliminateDevShowReportList`, {});
};


/** ------------------------------------------ */

export const getDevShowReportList = (data: any) => {
    return http.fetchPost(`${baseUrl}/devShowReport/getList`, data);
};

export const addDevShowReport = (data: any) => {
    return http.fetchPost(`${baseUrl}/devShowReport/add`, data);
};

export const editDevShowReport = (data: any) => {
    return http.fetchPost(`${baseUrl}/devShowReport/edit`, data);
};


/** ------------------------------------------ */

export const getSystemFeedbackList = (data: any) => {
    return http.fetchPost(`${baseUrl}/systemFeedback/getList`, data);
};

export const editSystemFeedback = (data: any) => {
    return http.fetchPost(`${baseUrl}/systemFeedback/edit`, data);
};


/** ------------------------------------------ */



export const getCurrentMonthUser = (data: any) => {
    return http.fetchGet(`${baseUrl}/reportController/getCurrentMonthUser`, data);
};


export const getCurrentMonthDevice = (data: any) => {
    return http.fetchGet(`${baseUrl}/reportController/getCurrentMonthDevice`, data);
};

export const getIMEIUsageInformation = (data: any) => {
    return http.fetchGet(`${baseUrl}/reportController/getIMEIUsageInformation`, data);
};

export const getOnlineAndOfflineInformation = (data: any) => {
    return http.fetchGet(`${baseUrl}/reportController/getOnlineAndOfflineInformation`, data);
};

export const getTotalNumberOfUsersAndDevices = (data: any) => {
    return http.fetchGet(`${baseUrl}/reportController/getTotalNumberOfUsersAndDevices`, data);
};



/** ------------------------------------------ */

export const getModelList = (data: any) => {
    return http.fetchPost(`${baseUrl}/devModel/getList`, data);
};

export const addModel = (data: any) => {
    return http.fetchPost(`${baseUrl}/devModel/add`, data);
};

export const editModel = (data: any) => {
    return http.fetchPost(`${baseUrl}/devModel/edit`, data);
};


/** ------------------------------------------ */

/**
 * 
 *    获取 多语言列表
 * @returns 
 */
export const getLanguageList = (data: any) => {
    return http.fetchPost(`${baseUrl}/language/getList`, data);
};


/**
 * 
 *    保存语言
 * @returns 
 */
export const editLanguage = (data: any) => {
    return http.fetchPost(`${baseUrl}/language/edit`, data);
};

export const addLanguage = (data: any) => {
    return http.fetchPost(`${baseUrl}/language/add`, data);
};

export const languageOneClickTranslation = (data: any) => {
    return http.fetchPost(`${baseUrl}/language/oneClickTranslation`, data);
};


/** ------------------------------------------ */


/** ------------------------------------------ */

/**
 * 
 *    获取 固件版本管理
 * @returns 
 */
export const getHardwareVersionList = (data: any) => {
    return http.fetchPost(`${baseUrl}/hardwareVersion/getList`, data);
};


/**
 * 
 *    保存固件版本
 * @returns 
 */
export const editHardwareVersion = (data: any) => {
    return http.fetchPost(`${baseUrl}/hardwareVersion/edit`, data);
};

export const addHardwareVersion = (data: any) => {
    return http.fetchPost(`${baseUrl}/hardwareVersion/add`, data);
};


/** ------------------------------------------ */

/**
 *    获取 多语言列表
 * @returns 
 */
export const getdemoImeiManageList = (data: any) => {
    return http.fetchPost(`${baseUrl}/demoImeiManage/getList`, data);
};


/**
 *    保存语言
 * @returns 
 */
export const editdemoImeiManage = (data: any) => {
    return http.fetchPost(`${baseUrl}/demoImeiManage/edit`, data);
};

export const addDemoImeiManage = (data: any) => {
    return http.fetchPost(`${baseUrl}/demoImeiManage/add`, data);
};



/** ------------------------------------------ */

/**
 *    获取 版本
 * @returns 
 */
export const getAppVersionList = (data: any) => {
    return http.fetchPost(`${baseUrl}/appVersion/getList`, data);
};


/**
 *    保存版本
 * @returns 
 */
export const editAppVersion = (data: any) => {
    return http.fetchPost(`${baseUrl}/appVersion/edit`, data);
};

export const addAppVersion = (data: any) => {
    return http.fetchPost(`${baseUrl}/appVersion/add`, data);
};


/**
 *    校验管理
 * @returns 
 */
export const devCheckEdit = (data: any) => {
    return http.fetchPost(`${baseUrl}/devCheck/edit`, data);
};

export const devCheckFind = (data: any) => {
    return http.fetchPost(`${baseUrl}/devCheck/find`, data);
};


export const devCheckAdd = (data: any) => {
    return http.fetchPost(`${baseUrl}/devCheck/add`, data);
};




/** ------------------------------------------ */



export const isEditAuthByCode = (code: any) => {

    // console.log('sdfsdf');
    if (code == 104) {
        let userInfo = JSON.parse(sessionStorage.getItem('userInfo') + "");
        if ("superadmin" == userInfo.account) {
            // return false;
        }
    }


    let editAuth = sessionStorage.getItem('editAuth');

    if (editAuth == null || editAuth == '') {
        //无权限
        return true;
    }
    if (editAuth.toLowerCase() == 'all') {
        //有所有的权限
        return false;
    }

    if (editAuth.indexOf(code) >= 0) {
        //alert(editAuth.indexOf(code + "") + " -- " + editAuth + " -- " + code + " -- " + true);
        //有code中的权限
        return false;
    }

    return true;
};

