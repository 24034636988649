
import { defineComponent, reactive, toRefs, watch, onMounted } from 'vue'
// import Sidebar from '@/components/Sidebar/index.vue'
import Header from '@/components/Header.vue'
import Navmenu from '@/components/Navmenu.vue'
import Footer from '@/components/Footer.vue'
// import {getIpAddress} from "@/api/index";
export default defineComponent({
  name: 'Home',
  components: {
    Navmenu,
    // Sidebar,
    Header,
    Footer,
  },
  setup() {
    const deviceData = reactive({
      options: [
        {
          value: 'Option1',
          label: 'Option1',
        },
        {
          value: 'Option2',
          label: 'Option2',
        },
        {
          value: 'Option3',
          label: 'Option3',
        },
        {
          value: 'Option4',
          label: 'Option4',
        },
        {
          value: 'Option5',
          label: 'Option5',
        },
      ],
      value: 'Option5', //弹窗展示
    })
    const data = toRefs(deviceData)
    const getIpAddressFun = async () => {
      // await getIpAddress({}).then((res)=>{
      //     console.log(res);
      // })
    }
    onMounted(() => {
      getIpAddressFun()
    })
    return {
      ...data,
      getIpAddressFun,
    }
  },
})
