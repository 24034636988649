
import { defineComponent,reactive,toRefs } from 'vue'
interface headData {
    footerText:string
}
export default defineComponent({
  name:'Footer',
  components: {
  },
  setup() {
    const deviceData:headData = reactive({
        footerText:'Copyright (C) 2015-2020深圳科帮电子有限公司 版权所有'
    });
    const data = toRefs(deviceData);
   
    return {
      ...data,
    }
  },
})
