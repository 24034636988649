<!--
 * @Author       : ZM
 * @Date         : 2021-12-21 10:20:44
 * @LastEditors  : ZM
 * @LastEditTime : 2022-02-11 18:14:39
 * @Description  : is me
-->
<template>
  <div style="height:100%;">
      <!-- <div style="display:flex;height:calc(100% - 40px);">
        <Navmenu />
        <div style="width: 100%;">
          <Header /> -->
          <router-view />
        <!-- </div>
      </div>
      <Footer /> -->
    </div>
</template>
<script>
import Header from "@/components/Header.vue";
import Navmenu from "@/components/Navmenu.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "app",
  components: {
      // Navmenu,
      // Header,
      // Footer,
  },
  created() {
      // this.isActive();
      // 在页面刷新时将vuex里的信息保存到localStorage里
      window.addEventListener('beforeunload', () => {
        localStorage.setItem('messageStore', JSON.stringify(this.$store.state))
      })
      // console.log('localStorage===============');
      // console.log(localStorage);
      // 在页面加载时读取localStorage里的状态信息
      localStorage.getItem('messageStore') && this.$store.replaceState(Object.assign(this.$store.state, JSON.parse(localStorage.getItem('messageStore'))))
    },
    // methods(){

    // }
};
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
