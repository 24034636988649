
import { defineComponent, ref,reactive,toRefs } from 'vue'
import { useStore } from "vuex";
import {changePassword} from "@/api/index";
import { ElMessage,ElMessageBox } from 'element-plus';
import { Md5 } from 'ts-md5/dist/md5';
// import Breadcrumb from './Breadcrumb.vue';
import {useRouter} from 'vue-router';
import {
  Expand,
  Fold,
  UserFilled,
  Edit,
  Back
} from '@element-plus/icons-vue'
interface headData {
    isCollapse:boolean,
    userName:string,
    editPasswordFlag:boolean,
    editPasswordForm:any
}
export default defineComponent({
  components: {
    Expand,
    Fold,
    UserFilled,
    Edit,
    Back,
    // Breadcrumb
  },
  setup() {
        const headerFef = ref();
    const store = useStore();
    const router = useRouter();

    let userInfo = JSON.parse(sessionStorage.getItem('userInfo')+"");


    const validateConfirmPass = (rule:any, value:any, callback:any) => {
             if (value === '') {
                callback(new Error('请输入确认密码！'))
            } else if (value !== deviceData.editPasswordForm.newPass) {
                callback(new Error("两次输入得密码不一致！"))
            } else {
                callback()
            }
        }
        
    const validateNewPass = (rule:any, value:any, callback:any) => {
             if (!value) {
                callback(new Error('请输入新密码'))
                return;
            } else if (value.length<6) {
                callback(new Error("密码最少6位"))
                return;
            } else if(/^(\d)\1+$/.test(value)){
                callback(new Error("密码不能全是一样字符"))
                return;
            }
            
            let ascii = value.charCodeAt(0);
            console.log("xxxx0 :  "+ascii);
            var str = value.replace(/\w/g, function($0:any, pos:any) {
                let num = $0.charCodeAt(0)-pos;
                if(num==ascii){
                    return 1;
                }
                return num;
            });
            console.log("xxxx1 : "+str +"   "+ascii);
            if (/^(\d)\1+$/.test(str)) 
            {
                callback(new Error("密码不能顺增"))
                return;
            }
            str = value.replace(/\w/g, function($0:any, pos:any) {
                let num = $0.charCodeAt(0)+pos;
                if(num==ascii){
                    return 1;
                }
                return num;
            });

            console.log("xxxx : "+str);
            if (/^(\d)\1+$/.test(str)){
                callback(new Error("密码不能顺减"))                  
                return;
            }

            callback()
        }

    const deviceData:headData = reactive({
        isCollapse:false,
        userName:userInfo.name,
        editPasswordFlag:false,
        editPasswordForm:{},
        rules:{
                pass: [
                    {required: true,message: '请输入原始密码',trigger: 'blur'},
                ],
                newPass: [
                {required: true,validator:validateNewPass,trigger: 'blur'},
                ],
                confirmPass: [
                    {required: true,validator:validateConfirmPass,trigger: 'blur'},
                ]
            },
    });
    const data = toRefs(deviceData);
    const dropdownItemClick = () =>{
        console.log('修改信息');
        deviceData.editPasswordForm = {};
        deviceData.editPasswordFlag = true;
    }
    const loginOut = ()=>{
        sessionStorage.clear();
        router.push('/login');
    }
    const isCollapseFun = ()=>{
        deviceData.isCollapse = !deviceData.isCollapse;
        store.commit('ISCOLLAPSE',deviceData.isCollapse);
    }

    const editPasswordSave = ()=>{
        console.log('xxxxxxxxxxxx');

        //headerFef
        headerFef.value.validate(async (valid: any) => {
                if (valid) {
                    console.log('修改密码');
                    let params ={
                        oldPassword : Md5.hashStr(deviceData.editPasswordForm.pass),
                        newPassword : Md5.hashStr(deviceData.editPasswordForm.newPass)
                    }
                    await changePassword(params).then((res:any)=>{
                        if(res.code == 0){
                            ElMessage.success('修改密码成功!');
                            deviceData.editPasswordFlag = false;
                        }else{
                            if(res.errMsg){
                                ElMessage.error(res.errMsg);
                            }else{
                                ElMessage.error('修改密码失败');
                            }
                        }
                    });
                } else {
                    return false;
                }
            });

    }


    return {
      ...data,
      dropdownItemClick,
      loginOut,
      isCollapseFun,
      editPasswordSave,
      headerFef
    }
  },
})
