/*
 * @Author       : ZM
 * @Date         : 2021-12-31 17:41:12
 * @LastEditors  : ZM
 * @LastEditTime : 2022-01-26 14:26:22
 * @Description  : is me
 */
import { App } from 'vue'

export default (app: App<Element>) => {
    //自定义组件
    app.directive('focus', (el: HTMLElement, binding) => {
        el.addEventListener('click', () => {
            console.log(66);
        })
    });
    app.directive('permission', (el: HTMLElement, binding) => {
        const { value } = binding
        const roles = ['editor','admin']
        if (value && value instanceof Array && value.length > 0) {
            const permissionRoles = value
            const hasPermission = roles.some(role => {
                return permissionRoles.includes(role)
            })
            if (!hasPermission) {
                el.parentNode && el.parentNode.removeChild(el)
            }
        } else {
            throw new Error(`need roles! Like v-permission="['admin','editor']"`)
        }
    });
}