/*
 * @Author       : ZM
 * @Date         : 2021-11-01 08:34:55
 * @LastEditors  : ZM
 * @LastEditTime : 2022-02-11 17:58:10
 * @Description  : is me
 */
import { InjectionKey } from "vue";
import { createStore, useStore as baseUseStore, Store } from "vuex";
import RootStateTypes, { AllStateTypes } from '@/store/interface';

import testModule from "./modules/control";
export default createStore<RootStateTypes>({
  state: {
    isCollapse:false,
    chooseIp:'',
  },
  getters:{},
  mutations: {
      ISCOLLAPSE(state,data){
          state.isCollapse = data;
      },
      CHOOSEIP(state,data){
          state.chooseIp = data;
      }
  },
  actions: {

  },
  modules: {
    testModule
  },
});

export const key:InjectionKey<Store<RootStateTypes>> = Symbol('vue-store');

export function useStore<T=AllStateTypes>() {
    return baseUseStore<T>(key);
}