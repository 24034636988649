/*
 * @Author       : ZM
 * @Date         : 2021-12-21 10:20:44
 * @LastEditors  : ZM
 * @LastEditTime : 2021-12-31 17:41:59
 * @Description  : is me
 */
import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import axios from "./plugins/axios";
import router from "./router";
import './permission'
import store from "./store";
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import directives from '@/directive/index';
import './assets/styles/reset.css'

createApp(App).use(store).use(router).use(axios).use(ElementPlus, {
    locale: zhCn,
}).use(ElementPlus).use(directives).mount("#app");
