import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-07c43dea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"height":"100%"} }
const _hoisted_2 = { style: {"display":"flex","height":"98%"} }
const _hoisted_3 = { style: {"width":"calc(100% - 200px)"} }
const _hoisted_4 = { style: {"height":"calc(100% - 50px)"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navmenu = _resolveComponent("Navmenu")!
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Navmenu),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Header),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_router_view)
        ]),
        _createVNode(_component_Footer)
      ])
    ])
  ]))
}