
import { defineComponent, watch, reactive, toRefs } from 'vue'
import {
  // DataBoard,
  Location,
  Document,
  //   Menu as IconMenu,
  Setting,
} from '@element-plus/icons-vue'
// import { useStore } from './../store';
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
export default defineComponent({
  components: {
    // DataBoard,
    Location,
    // Document,
    Setting,
    // IconMenu,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const deviceData = reactive({
      isCollapse: false,
      activeNum: '1',
    })
    watch(
      () => store.state.isCollapse,
      () => {
        deviceData.isCollapse = store.state.isCollapse
      }
    )
    const data = toRefs(deviceData)
    const handleOpen = (key: any, keyPath: any) => {
      console.log(key, keyPath)
      switch (key) {
        case '2': {
          router.replace({
            path: '/imeiTableAll',
          })
          deviceData.activeNum = '2-1'
          break
        }
        case '3': {
          router.replace({
            path: '/userTable',
          })
          deviceData.activeNum = '3-1'
          break
        }
      }
    }
    const handleClose = (key: any, keyPath: any) => {
      console.log(key, keyPath)
    }
    const handleSelect = (key: any, keyPath: any) => {
      // console.log(key);
      switch (key) {
        case '2-1': {
          router.replace({
            path: '/imeiTableAll',
          })
          deviceData.activeNum = '2-1'
          break
        }
        case '2-2': {
          router.replace({
            path: '/carImei',
          })
          deviceData.activeNum = '2-2'
          break
        }
        case '2-3': {
          router.replace({
            path: '/watchImei',
          })
          deviceData.activeNum = '2-3'
          break
        }
        case '2-4': {
          router.replace({
            path: '/dogImei',
          })
          deviceData.activeNum = '2-4'
          break
        }
        case '0': {
          router.replace({
            path: '/',
          })
          deviceData.activeNum = '0'
          break
        }
        case '1': {
          router.replace({
            path: '/',
          })
          deviceData.activeNum = '1'
          break
        }
        case '3-1': {
          router.replace({
            path: '/userTable',
          })
          deviceData.activeNum = '3-1'
          break
        }
        case '3-2': {
          router.replace({
            path: '/roleTable',
          })
          deviceData.activeNum = '3-2'
          break
        }

        case '3-3': {
          router.replace({
            path: '/productSpecificationsTable',
          })
          deviceData.activeNum = '3-3'
          break
        }
        
        case '3-4': {
          router.replace({
            path: '/devShowReportTable',
          })
          deviceData.activeNum = '3-4'
          break
        }

        case '3-5': {
          router.replace({
            path: '/systemFeedbackTable',
          })
          deviceData.activeNum = '3-5'
          break
          }
        
        case '3-6': {
          router.replace({
            path: '/modelTable',
          })
          deviceData.activeNum = '3-6'
          break
          }
        
        case '3-7': {
          router.replace({
            path: '/sysLanguageTable',
          })
          deviceData.activeNum = '3-7'
          break
          }
        
        case '3-8': {
          router.replace({
            path: '/demoImeiManageTable',
          })
          deviceData.activeNum = '3-8'
          break
        }
        
        case '3-9': {
          router.replace({
            path: '/appVersionTable',
          })
          deviceData.activeNum = '3-9'
          break
          }
          
        case '3-10': {
          router.replace({
            path: '/devCheckTable',
          })
          deviceData.activeNum = '3-10'
          break
          }
        
          case '3-11': {
          router.replace({
            path: '/hardwareVersionTable',
          })
          deviceData.activeNum = '3-11'
          break
          }

        case '4': {
          router.replace({
            path: '/manageTable',
          })
          deviceData.activeNum = '4'
          break
        }
      }
    }
    return {
      ...data,
      handleOpen,
      handleClose,
      handleSelect,
    }
  },
})
