/*
 * @Author       : ZM
 * @Date         : 2021-11-01 08:34:55
 * @LastEditors  : ZM
 * @LastEditTime : 2022-02-10 17:45:06
 * @Description  : is me
 */
import axios from "axios";
axios.defaults.timeout = 15000;
axios.defaults.headers.post["Content-Type"] = "application/json;chartset=UTF-8";
let token = sessionStorage.getItem('token');
if (token) {
    axios.defaults.headers.Authorization = token;
}

export default {
    fetchGet(url: string, params: any = {}) {
        if (!(params instanceof URLSearchParams)) {
            // alert('URLSearchParams');
            let newParams = new URLSearchParams();

            let keys = Object.keys(params);
            for (let key of keys) {
                newParams.append(key, params[key]);
            }
            params = newParams;
        }
        return new Promise((resolve, reject) => {
            axios
                .get(url, { params })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    console.log(url + "----" + error)
                    reject(error);
                });
        });
    },
    fetchPost(url: string, params: any = {}) {
        if (!(params instanceof URLSearchParams)) {
            // alert('URLSearchParams');
            let newParams = new URLSearchParams();

            let keys = Object.keys(params);
            for (let key of keys) {
                newParams.append(key, params[key]);
            }
            params = newParams;
        }

        return new Promise((resolve, reject) => {
            axios
                .post(url, params)
                .then((res) => {
                    resolve(res.data);
                })
                .catch((error) => {
                    console.log(url + "----" + error)
                    reject(error);
                });
        });
    },
};
