/*
 * @Author       : ZM
 * @Date         : 2021-12-21 10:20:44
 * @LastEditors  : ZM
 * @LastEditTime : 2022-02-14 10:04:29
 * @Description  : is me
 */
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "Home",
        component: Home,
        // redirect:'/serviceManage',
        children: [
            // {
            //   path:'/',
            //   name: "DataVis",
            //   component: () => import("../components/DataVis.vue"),
            //   meta: { title: '首页', activeMenu: '/' }
            // },
            // {
            //     path: '/',
            //     name: "ServiceManage",
            //     component: () => import("../components/ServiceManage.vue"),
            //     meta: { title: '服务器管理', activeMenu: '/serviceManage' }
            // },
            {
                path: '/',
                name: "Home",
                component: () => import("../components/HomeReport.vue"),
                meta: { title: '主页报表', activeMenu: '/HomeReport' }
            },
            {
                path: '/imeiTableAll',
                name: "ImeiTableAll",
                component: () => import("../components/imeiTable/ImeiTableAll.vue"),
                meta: { title: 'IMEI号管理，全部', activeMenu: '/imeiTable/ImeiTableAll' }
            },
            {
                path: 'carImei',
                name: "CarImei",
                component: () => import("../components/imeiTable/CarImei.vue"),
                meta: { title: 'IMEI号管理，车载设备', activeMenu: '/imeiTable/carImei' }
            },
            {
                path: 'watchImei',
                name: "WatchImei",
                component: () => import("../components/imeiTable/WatchImei.vue"),
                meta: { title: 'IMEI号管理，手表设备', activeMenu: '/imeiTable/watchImei' }
            },
            {
                path: 'dogImei',
                name: "DogImei",
                component: () => import("../components/imeiTable/DogImei.vue"),
                meta: { title: 'IMEI号管理，电子宠物', activeMenu: '/imeiTable/dogImei' }
            },
            {
                path: 'robotImei',
                name: "RobotImei",
                component: () => import("../components/imeiTable/RobotImei.vue"),
                meta: { title: 'IMEI号管理，扫地机器人', activeMenu: '/imeiTable/robotImei' }
            },
            {
                path: 'userTable',
                name: "UserTable",
                component: () => import("../components/UserTable.vue"),
                meta: { title: '用户管理', activeMenu: '/userTable' }
            },
            {
                path: 'modelTable',
                name: "ModelTable",
                component: () => import("../components/ModelTable.vue"),
                meta: { title: '产品管理', activeMenu: '/modelTable' }
            },
            {
                path: 'productSpecificationsTable',
                name: "ProductSpecificationsTable",
                component: () => import("../components/ProductSpecificationsTable.vue"),
                meta: { title: '产品规格管理', activeMenu: '/productSpecificationsTable' }
            },
            {
                path: 'devShowReportTable',
                name: "DevShowReportTable",
                component: () => import("../components/DevShowReportTable.vue"),
                meta: { title: '报表显示控制表', activeMenu: '/devShowReportTable' }
            },
            {
                path: 'systemFeedbackTable',
                name: "SystemFeedbackTable",
                component: () => import("../components/SystemFeedbackTable.vue"),
                meta: { title: '反馈回复', activeMenu: '/systemFeedbackTable' }
            },
            {
                path: 'sysLanguageTable',
                name: "SysLanguageTable",
                component: () => import("../components/SysLanguageTable.vue"),
                meta: { title: '多语言管理', activeMenu: '/sysLanguageTable' }
            },
            {
                path: 'demoImeiManageTable',
                name: "DemoImeiManageTable",
                component: () => import("../components/DemoImeiManageTable.vue"),
                meta: { title: '演示IMEI对照管理', activeMenu: '/demoImeiManageTable' }
            },
            {
                path: 'appVersionTable',
                name: "AppVersionTable",
                component: () => import("../components/AppVersionTable.vue"),
                meta: { title: '多语言管理', activeMenu: '/appVersionTable' }
            },
            {
                path: 'devCheckTable',
                name: "DevCheckTable",
                component: () => import("../components/DevCheckTable.vue"),
                meta: { title: '校验管理', activeMenu: '/devCheckTable' }
            },
            {
                path: 'hardwareVersionTable',
                name: "HardwareVersionTable",
                component: () => import("../components/HardwareVersionTable.vue"),
                meta: { title: '固件版本管理', activeMenu: '/hardwareVersionTable' }
            },
            {
                path: 'roleTable',
                name: "RoleTable",
                component: () => import("../components/RoleTable.vue"),
                meta: { title: '角色管理', activeMenu: '/roleTable' }
            },
            {
                path: 'manageTable',
                name: "ManageTable",
                component: () => import("../components/ManageTable.vue"),
                meta: { title: '管理员管理', activeMenu: '/manageTable' }
            },
        ]
    },
    {
        path: "/login",
        name: "Login",
        component: () => import("../views/Login.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
