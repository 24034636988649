/*
 * @Author       : ZM
 * @Date         : 2021-11-01 08:34:55
 * @LastEditors  : ZM
 * @LastEditTime : 2021-12-10 11:41:46
 * @Description  : is me
 */
import { Module } from "vuex";
import RootStateTypes from '@/store/interface';
import TestModuleTypes from '@/store/modules/control/interface';

const testModule:Module<TestModuleTypes,RootStateTypes> = {
    namespaced:process.env.NODE_ENV !== 'production',
    state:{
        name:'1213',
        conut:1,
        
        devicesAllId:0,
        treeCheckedArr:[], //左侧树选中的
    },
    mutations:{
        
        ADD_NAME(state,data){
            state.name = data;
        },
        ADD_CHECKED_ARR(state,data){
            state.treeCheckedArr.push(data);
        },
        DELETE_CHECKED_ARR(state,data){
            let index = state.treeCheckedArr.indexOf(data);
            state.treeCheckedArr.splice(index,1);
        },

        ADD_DEVICESALLID(state,data){  //设备列表树
            state.devicesAllId = data;
        },
        FILTER_TREE_FID(state,data){
            state.treeCheckedArr = data;
        },
    },
    actions:{

    }
};
export default testModule;